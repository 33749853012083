<template>
    <!-- 编辑信息 -->
  <div class="detail-edit">
        <div class="detail">
            <el-row type="flex" :gutter="20" class="row-bg" justify="space-between" v-for="(item,index) in FormCol" :key="'detail'+index">
                
                <el-col :span="item && item.length === 1 ? 16 : 8 " v-for="(v,i) in item" :key="'detail-item'+i">
                    <div class="detail-item" >
                        <div class="item-label" v-if="v.label">{{v.label}}</div>
                        <div class="item-change" v-if="isEdit">
                            <el-select v-model="form[v.props]" placeholder="请选择" v-if="v.type === 'select'">
                                <el-option
                                    v-for="item in v.options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                            <el-input v-model.trim="form[v.props]" clearable :placeholder="v.placeholder" v-else-if="v.type === 'input'" :disabled="v.disabled"></el-input>
                            <el-date-picker
                                v-else-if="v.type === 'date'"
                                v-model="form[v.props]"
                                type="date"
                                clearable
                                format="yyyy年MM月dd日"
                                value-format="yyyy-MM-dd"     
                                :placeholder="v.placeholder"
                                @change="handleDate(v.props)">
                            </el-date-picker>
                            <div class="item-arr-input" v-else-if="v.type === 'arr-input'">
                                <el-input v-model.trim="form[v.props]" placeholder="请输入姓名" class="arr-name"></el-input>
                                <el-input v-model.trim="form[v.props+'_phone']" placeholder="请输入手机号" class="arr-phone"></el-input>
                            </div>
                            <el-autocomplete
                                v-else-if="v.type === 'autocomplete'"
                                class="autocomplete-input marginleft"
                                v-model="form[v.props]"
                                :fetch-suggestions="querySearch"
                                :placeholder="v.placeholder"
                                @select="handleQuerySelect"
                            ></el-autocomplete>
                        </div>
                        <div class="item-text" v-else>
                            <span v-if="v.type === 'arr-input'">
                                {{form[v.props]}}/{{form[v.props+'_phone']}}
                            </span>
                            <span v-else-if="v.type === 'autocomplete'"></span>
                            <span v-else-if="v.props === 'item'">{{form[v.props]}} {{form['belong'] ? `【${form['belong']}】` : ''}}</span>
                            <span v-else>{{form[v.props]}}</span>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8" class="other-btn" v-if="index === 0">
                    <el-button v-if="!isEdit" @click="toEdit">编辑</el-button>
                    <el-button v-if="isEdit" @click="handleCancel">取消</el-button>
                    <el-button v-if="isEdit" type="primary" @click="handleSave">保存</el-button>
                </el-col>
            </el-row>
        </div>

  </div>
</template>
<script>
import { modEasyMainInfo,getBelongLis } from '@/service/help'
import { checkEndDate } from '@/utils/utils'
export default {

    name:'detail-edit',
    props:{
        info:Object
    },
    data(){
        return{
            FormCol:[
                [{label:'',props:'item',type:'input',placeholder:'请输入项目名称'},{label:'',props:'belong',type:'autocomplete',placeholder:'请输入类型',options:[]}],
                [{label:"开始时间：",props:'start_time',type:'date',placeholder:'请输入开始时间'},{label:"维保期：",props:'limit',type:'input',placeholder:'',disabled:true},{}],
                [{label:"结束/续约时间：",props:'end_time',type:'date',placeholder:'请输入结束/续约时间'},{label:"现场联系人：",props:'witness',type:'arr-input',},{label:"维保责任人：",props:'owner',type:'arr-input',},],
                [{label:"累计维护次数：",props:'em_num',type:'input',disabled:true},{label:"最近一次维护：",props:'em_up_time',type:'input',disabled:true},{label:"下一次维护：",props:'next',type:'date',placeholder:'请输入下一次维护时间'}]
            ],
            form:{
                "item": "",
                "belong": "",
                "witness_phone": "",
                "witness": "",
                "owner_phone": "",
                "owner": "",
                "start_time": "",
                "end_time": "",
                "limit": "",
                "next": "",
                "rtenewal_time": ""
            },
            isEdit:false
        }
    },
    created(){
        this.init()
    },
    methods:{
        handleQuerySelect(e){
            this.form.belong = e.value
        },
        querySearch(queryString, cb){
            getBelongLis().then(res => {
                if(res.data.success) {
                    this.projectOptions = res.data.data.map(v => {
                        return {
                            value: v,
                            address: v
                        }
                    })
                    this.queryData(queryString, cb);
                }
            })
        },
        queryData(queryString, cb){
            var arr = this.projectOptions;
            var results = queryString ? arr.filter(this.createStateFilter(queryString)) : arr;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                cb(results);
            }, 300);
        },
        createStateFilter(queryString) {
            return (state) => {
                return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        handleDate(props){
            if(props === 'start_time' || props === 'end_time'){
                if(checkEndDate(this.form.start_time,this.form.end_time)){
                    this.$message({
                        type: 'error',
                        message: '开始日期不能大于结束日期！',
                    });
                    this.form.start_time = ''
                    return
                }
                // 获取时间戳 计算相差的年份
                if(this.form.start_time && this.form.end_time){
                    let num = new Date(this.form.end_time).getTime() - new Date(this.form.start_time).getTime()
                    let d = (num / 1000 / 60 / 60 / 24 / 365).toFixed(1)
                    this.form['limit'] = d + '年' 
                    console.log(d,'y.getFullYear')
                }
            }
        },
        handleSave(){
            modEasyMainInfo(this.form).then(res=>{
                let {success,data} = res.data
                if(success){
                    this.$message({
                        type: 'success',
                        message: '编辑成功！',
                    });
                    this.isEdit = false
                }
            })
        },
        handleCancel(){
            this.isEdit = false
            this.init()
        },
        init(){
           this.form = Object.assign(this.form,this.info)
        },
        toEdit(){
            this.isEdit = true
        }
    }

};
</script>
<style lang="less">
    .detail-edit{
        padding: 8px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #E8ECEF;
        .detail-item{
            font-size: 14px;
            margin-bottom: 14px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .item-change{
                width: 100%;
            }
            .el-date-editor{
                width: 100%;
            }
        }

        .item-label{
            width: 105px;
            color: rgba(133, 144, 179, 1);
        }
        .item-text{
            color: rgba(51, 70, 129, 1);
            font-size: 16px;
        }
        .item-arr-input{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .arr-name{
                margin-right: 10px;
            }
        }
        .other-btn{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .el-button{
                margin: 0;
                margin-right: 20px;
            }
        }
    }
    
</style>