<template>
<!-- 轨迹 -->
  <div class="easy-trajectory">
    <table>
        <tr>
          <th>操作时间</th>
          <th>操作人</th>
          <th>操作内容</th>
          <th>操作前</th>
          <th>操作后</th>
        </tr>
        <tr v-for="mark in trajectory" :key="mark.id">
          <td>{{ mark.ctime }}</td>
          <td>
            <div class="head-draw head">{{ lastName(mark.uname) }}</div>
            {{ mark.uname }}
          </td>
          <td>{{ mark.content }}</td>
          <td>
            <div class="trajectory-content" v-html="mark.before"></div>
          </td>
          <td>
            <div class="trajectory-content" v-html="mark.after"></div>
          </td>
        </tr>
      </table>

  </div>
</template>
<script>
export default {

    name:'trajectory',
    props:{
        info:Object
    },
    data(){
        return{
            trajectory:[]
        }
    },
    created(){
        this.trajectory = JSON.parse(JSON.stringify(this.info.logs))
    },
    methods:{
    }

};
</script>
<style lang="less">
    .easy-trajectory {
        height: calc(~'100% - 40px');
        overflow-y: auto;
        table {
            margin-top: 10px;
            border: none;
            width: 100%;
        th {
            color: #8590b3;
        }
        td {
            font-size: 13px;
            color: #334681;
            .head {
                vertical-align: middle;
                font-size: 10px;
                width: 30px;
                height: 30px;
                line-height: 30px;
                margin-right: 5px;
            }
        }
        th,
        td {
            vertical-align: top;
            padding: 15px 0px 10px 20px;
            min-height: 50px;
            border-bottom: 1px solid #e8ecef;
        }
        th:nth-child(1),
        td:nth-child(1) {
            width: 110px;
        }
        th:nth-child(2),
        td:nth-child(2) {
            padding-top: 10px; //兼顾头像
            width: 95px;
        }
        th:nth-child(2) {
            padding-top: 15px;
        }
        th:nth-child(3),
        td:nth-child(3) {
            width: 95px;
        }
        th:nth-child(4),
        td:nth-child(4) {
            width: 145px;
            height: 50px;
            div {
            width: 145px;
            overflow-x: hidden;
            }
        }
        th:nth-child(5),
        td:nth-child(5) {
            width: 143px;
            height: 50px;
            div {
            width: 163px;
            overflow-x: hidden;
            }
        }
        .trajectory-content{
            ::v-deep img{
            max-width: 100%;
            }
        }
    }
  }
</style>