<template>
  <div class="detail-tab">
        <div :class="['tab-item',v.active]" v-for="(v,i) in tab" :key="'tab'+i" @click="handleTab(v)">
            {{v.label}}
        </div>
  </div>
</template>
<script>
export default {
    name:'detail-tab',
    props:{
        tab:{
            type:Array,
            default:()=>[]
        }
    },
    data(){
        return{

        }
    },
    methods:{
        handleTab(obj){
            this.$emit('handleTab',obj)
        }
    }
};
</script>
<style lang="less">
    .detail-tab{
        height: 50px;
        background: rgba(245, 247, 250, 1);
        display: flex;
        align-content: center;
        justify-content: flex-start;
        border-bottom: 1px solid rgba(232, 236, 239, 1);
        .tab-item{
            width: 135px;
            border-right: 1px solid rgba(232, 236, 239, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #334681;
            cursor: pointer;
            &.active{
                color: rgba(19, 191, 255, 1);
                background: #fff;
            }
        }
    }
</style>