<template>
  <div class="maintain-search">
     <div class="page-title">
        {{pageTitle}}
     </div>
     <div class="search-box">
        <el-form ref="form" :model="form" label-width="80px" :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model.trim="form.key" class="key-input" placeholder="搜索" prefix-icon="el-icon-search" clearable @clear="clearKey" @keydown.enter.native="handleSearch"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button :type="form.is_mon_re ? 'primary' : ''" @click="handleBtn('is_mon_re')">筛选1个月内有的新的提醒项目</el-button>
            </el-form-item>

            <el-form-item>
                <el-select v-model="form.belong" placeholder="请选择类型" clearable @change="handleChange">
                    <el-option
                        v-for="item in belongOptions"
                        :key="'belong'+item"
                        :label="item"
                        :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="search-btn">
            <el-button type="primary" @click="handleAdd">新增</el-button>
        </div>
     </div>
    
    <!-- 新增表单 -->
    <el-dialog
        title="新建项目"
        :visible.sync="dialogVisible"
        width="660"
        :modal-append-to-body="false"
        :before-close="handleClose">
        <AddProject v-if="dialogVisible" @handleCancel="dialogVisible = false" @handleSubmit="handleSubmit"></AddProject>
    </el-dialog>
  </div>
</template>
<script>
import AddProject from './AddProject.vue'
import { addEasyMainNew, getBelongLis} from '@/service/help'
export default {
    name:'maintain-search',
    props:{
        pageTitle:String
    },
    components:{
        AddProject
    },
    data(){
        return{
            form:{
                key:'',
                is_mon_re:false,
                belong:""
            },
            belongOptions:[],
            // 新增
            dialogVisible:false,

        }
    },
    created(){
        this.handleSearch()
        // 获取belong列表
        this.getBelongLis()
    },
    methods:{
        handleChange(e){
            this.handleSearch()
        },
        getBelongLis(){
            getBelongLis().then(res=>{
                console.log(res,'0000')
                let {success,data} = res.data
                if(success){
                    this.belongOptions = data.filter(v=> {return v !== ""})
                }
            })
        },
        handleSubmit(form){
            addEasyMainNew(form).then(res=>{
                let {success,msg} = res.data
                if(success){
                    this.$message({
                        type: 'success',
                        message: '新建成功！',
                    });
                    this.dialogVisible = false
                    this.handleSearch()
                }else{
                    this.$message({
                        type: 'error',
                        message: msg,
                    });
                }
            })
        },
        handleClose(){
            this.dialogVisible = false
        },
        handleAdd(){
           this.dialogVisible = true
        },
        handleBtn(key){
            this.form[key] = !this.form[key]
            this.handleSearch()
        },
        clearKey(){
            this.form.key = ''
            this.handleSearch()
        },
        handleSearch(){
            this.$emit('handleSearch',this.form)
        }
    }
};
</script>
<style lang="less">
@import '~@/assets/style/maintain/easymaintain.less';
</style>