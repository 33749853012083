
<template>
  <div class="easy-main-record">
    <div class="record-top">
        <h6>维保记录</h6>
        <div class="add-record" @click="addRecord"><i class="el-icon-plus"></i>新增</div>
    </div>
    <div class="record-table">
        <common-table
            cname="main-record"
            :tabCol="tabCol"
            :dataLis="dataLis"
        ></common-table>
    </div>

    <el-dialog
      width="300"
      title="新建维保记录"
      :visible.sync="addVisible"
      append-to-body>
      <div class="add-record">
         <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="类型">
                <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入类型"
                    v-model="form.type">
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleSubmit">确定</el-button>
                <el-button @click="addVisible = false">取消</el-button>
            </el-form-item>
         </el-form>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import CommonTable from '@/components/maintain/CommonTable.vue';
import { EasyMainRecordCol } from '@/contants/tabCol.js'
import { getEasyMainInfo,addMaintenance } from '@/service/help'
export default {

    name:'maintain-record',
    components: { CommonTable },
    props:{
        info:Object
    },
    data(){
        return{
            tabCol:EasyMainRecordCol,
            dataLis:[],
            // 新建
            addVisible:false,
            form:{
                type:''
            }
        }
    },
    created(){
        this.dataLis = JSON.parse(JSON.stringify(this.info.e_miantenance))
        this.dataLis.forEach(v=>{
            v['status_text'] = v.status === 0 ? '待维保' : '已维保'
            v['status_color'] = v.status === 0 ? 'warning' : 'success'
        })
    },
    methods:{
        
        getMainInfo(){
            getEasyMainInfo({id:this.info.id}).then(res=>{
                let {success,data} = res.data
                if(success){
                    this.dataLis = data.e_miantenance
                    this.dataLis.forEach(v=>{
                        v['status_text'] = v.status === 0 ? '待维保' : '已维保'
                        v['status_color'] = v.status === 0 ? 'warning' : 'success'
                    })
                }
            })
        },
        handleSubmit(){
            addMaintenance({id:this.info.id,type:this.form.type,app_token:''}).then(res=>{
                let {success,data} = res.data
                if(success){
                    this.$message({
                        type: 'success',
                        message: '新增成功！',
                    });
                    this.addVisible = false
                    this.getMainInfo()
                }
            })
        },
        addRecord(){
            this.form.type = ''
            this.addVisible = true
        }
    }

};
</script>
<style lang="less">
    .easy-main-record{
        flex: 4;
        min-height: 340px;
        max-height: 340px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #E8ECEF;
        .record-top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding:  10px 16px 5px 16px;
            .add-record{
                cursor: pointer;
                color: rgba(19, 191, 255, 1);
            }
        }
        .record-table{
            width: 100%;
            box-sizing: border-box;
            padding: 0 16px;
            height: calc(~'100% - 35px');
        }
    }
</style>

