<template>
  <div class="maintain-detail">
    <!-- 维修更新记录 -->
      <div class="search">
          <el-input v-model.trim="key" class="key-input" placeholder="搜索" prefix-icon="el-icon-search" clearable @clear="getRecordLis" @keydown.enter.native="getRecordLis"></el-input>
          <el-button type="primary" @click="handleAdd">新增</el-button>
      </div>
      <div class="file-table">
          <common-table
            cname="hardware"
            :tabCol="tabCol"
            :dataLis="dataLis"
            @handleEdit="handleEdit"
            @handleDel="handleDel"
        ></common-table>
      </div>
      <!-- t弹窗 -->
      <el-dialog
          :visible.sync="dialogVisible"
          width="300"
          :title="dialogTitle"
          :append-to-body="true"
      >
          <form-edit :FormCol="FormCol" :pform="pform" v-if="dialogVisible" @handleCancel="dialogVisible = false" @handleSave="handleSave"></form-edit>
      </el-dialog>
  </div>
</template>
<script>
import CommonTable from '@/components/maintain/CommonTable.vue';
import FormEdit from './sub/formEdit.vue';
import { EasyMainUpdateCol,HardwareTypeOptions } from '@/contants/tabCol.js'
import { addRecord,delRecord,modRecord,getRecordLis,getHardwareLis} from '@/service/help'
export default {

    name:'maintain-hardware',
    components:{
      CommonTable,
      FormEdit
    },
    props:{
      id:Number
    },
    data(){
        return{
            key:'',
            tabCol:EasyMainUpdateCol,
            dataLis:[],
            // 弹窗
            dialogTitle:'新建维修记录',
            dialogVisible:false,
            FormCol:[
              {label:'名称',props:'title',type:'input',placeholder:'请输入名称'},
              {label:'类型',props:'type',type:'select',placeholder:'请选择类型',option:HardwareTypeOptions},
              {label:'关联硬件',props:'hardware_id',type:'select',placeholder:'请选择硬件',option:[]},
              {label:'维修时间',props:'date',type:'date',placeholder:'请输入维修时间'},
              {label:'内容',props:'content',type:'textarea',placeholder:'请输入内容'},
              {label:'上传文件',props:'files',type:'arr-upload',placeholder:'（仅支持PPT、PDF、word、jpg、png格式文件）'},
            ],
            pform:{
              title:'',
              files:[],
              type:'',
              date:'',
              hardware_id:'',
              content:''
            }
        }
    },
    created(){
      // 获取硬件列表
      this.getHardwaresLis()
      this.getRecordLis()
    },
    methods:{
      getHardwaresLis(){
        getHardwareLis({id:this.id}).then(res=>{
          let {success,data} = res.data
          if(success){
              this.FormCol[2].option = data.map(v=>{ return {...v,label:v.hardware,value:v.id}})
          }
        })
      },
      handleEdit(row){
        console.log(row,'===row===')
        this.dialogTitle = '编辑维修记录'
        this.pform = Object.assign(this.pform,{...row,files:row.picture})
        this.dialogVisible = true
      },
      handleDel(row){
        this.$confirm('是否删除该条记录？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          showClose: false,
          type: 'warning',
          center: true,
        })
        .then(() => {
          delRecord({
            id:row.id
          }).then(res=>{
            let {success,data} = res.data
            if(success){
              this.$message({
                    type: 'success',
                    message: '删除成功!',
              });
              this.getRecordLis()
            }
          })
        })
        .catch(() => {
         
        });
      },
      getRecordLis(){
        getRecordLis({
          id:this.id,
          key:this.key
        }).then(res=>{
          let {success,data} = res.data
          if(success){
            this.dataLis = data.map(v=>{
              v['status_text'] = v.type 
              v['status_color'] = v.type === '报废' ?   'error' : 'success'
              return v
            })
          }
        })
      },
      handleSave(data){
        console.log(data,'====data===')
        let url = this.dialogTitle == '新建维修记录' ? addRecord : modRecord
        url({
          id:this.dialogTitle == '新建维修记录' ? this.id : this.pform.id,
          ...data,
          picture:data.files,
          hardware_id:data.hardware_id.toString()
        }).then(res=>{
          let {success,data} = res.data
          if(success){
            this.$message({
                  type: 'success',
                  message: '保存成功!',
            });
            this.dialogVisible = false
            this.getRecordLis()
          }
        })
      },
      handleAdd(){
        this.dialogTitle = '新建维修记录'
        this.pform = {
          title:'',
          files:[],
          type:'',
          date:'',
          hardware_id:'',
          content:''
        }
        this.dialogVisible = true
      }

    }

};
</script>
<style lang="less">
@import '~@/assets/style/maintain/maintaindetail.less';
</style>