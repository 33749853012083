<template>
    <div class="form-box">
        <el-form ref="form" :model="form" label-width="80px" v-if="form">
            <el-form-item :label="v.label" v-for="(v,i) in FormCol" :key="'col'+i">
                <el-input v-model.trim="form[v.props]" :placeholder="v.placeholder" v-if="v.type == 'input'"></el-input>

                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    :placeholder="v.placeholder"
                    v-model.trim="form[v.props]"
                    v-else-if="v.type == 'textarea'"
                >
                </el-input>

                <!-- 单位输入框 -->

                <div v-else-if="v.type == 'unit-input'" class="unit-box">
                    <el-input v-model.trim="form[v.props]" :placeholder="v.placeholder" style="flex:1;margin-right:20px"></el-input> <span>{{v.unit}}</span>
                </div>

                <!-- 上传文件 -->
                <div class="upload-btn" v-else-if="v.type == 'arr-upload'">
                    <el-upload
                        class="upload-demo"
                        name="uploadfile"
                        action="https://up.eai9.com"
                        :before-upload="hanleBeforeUpload"
                        :on-remove="handleFileMove"
                        :on-change="handleFileChange"
                        :file-list="form[v.props]">
                        <el-button size="small" icon="el-icon-upload2">上传文件</el-button>
                        <div slot="tip" class="el-upload__tip">{{v.placeholder}}</div>
                    </el-upload>
                </div>

                <!-- 选择框 -->
                <el-select v-model="form[v.props]" :placeholder="v.placeholder" v-else-if="v.type == 'select'">
                    <el-option
                        v-for="item in v.option"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>

                <!-- 日期选择框 -->
                <el-date-picker
                    v-else-if="v.type == 'date'"
                    v-model="form[v.props]"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :placeholder="v.placeholder">
                </el-date-picker>
                
                

                
            </el-form-item>
            <el-form-item>
                 <!-- 按钮 -->
                <div class="meeting-btns">
                    <el-button type="primary" size="small" @click="handleSave">保存</el-button>
                    <el-button size="small" @click="handleCancel">取消</el-button>
                </div>
            </el-form-item>
        </el-form>
    </div>

</template>

<script>
export default {
    name:'form-box',
    props:{
        FormCol:{
            type:Array,
            default:()=>[]
        },
        pform:{
            type:Object
        }
    },
    data(){
        return{
            form:{
                files:[]
            },
            
        }
    },
    created(){
        this.init()
    },
    methods:{
        init(){
            this.form = JSON.parse(JSON.stringify(Object.assign(this.form,this.pform)))
            console.log(this.form,'=this.form')
        },
        handleSave(){
            this.$emit('handleSave',this.form)
        },
        handleCancel(){
            this.$emit('handleCancel')
        },
        // 上传
        hanleBeforeUpload(file){
            console.log(file,'======files======')
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg';
            const isPng = file.type === 'image/png';
            const isWord = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            const isPdf = file.type === 'application/pdf'
            const isPPT = file.type === 'application/vnd.ms-powerpoint' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'

            if (isJPG || isPng || isWord || isPdf || isPPT) {
                return true
            }else{
                this.$message({
                    type: 'error',
                    message: '文件格式错误！',
                });
                return false
            }
            
        },
        handleFileMove(file, fileList){
            console.log(file, fileList,'====file, fileList')
            this.form.files = fileList
        },
        handleFileChange(file,fileList){
            console.log(file,fileList,'====file====')
            if(file.status == 'success'){
                this.form.files.push({name:file.name,url:file.response.data})
            }
            
        },
    }
}
</script>


<style lang="less">
    .unit-box{
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>