<template>
  <div class="easymaintain">
     <Search
        pageTitle="项目列表"
        @handleSearch="handleSearch"
     ></Search>
     <div class="maintain-table">
        <CommonTable
            :tabCol="tabCol"
            :dataLis="dataLis"
            @handleRow="handleRow"
           
        >
         <!-- @handleEdit="handleRow"
            @handleDel="handleDel" -->
        </CommonTable>
     </div>

     <!-- 详情 -->
     <el-dialog
        :visible.sync="dialogVisible"
        width="80%"
        class="maintain-detail-dialog"
        :append-to-body="true"
    >
        <template slot="title">
            <DetailTab :tab="tab" @handleTab="handleTab"></DetailTab>
        </template> 
        <component :is="currTab" :id="id" :key="new Date().getTime()"></component>

    </el-dialog>
  </div>
</template>
<script>
import Search from '@/components/maintain/Search.vue'
import CommonTable from '@/components/maintain/CommonTable.vue'
import MaintainDetail from '@/components/maintain/MaintainDetail/detail.vue'
import MaintainFile from '@/components/maintain/MaintainDetail/file.vue'
import MaintainHardware from '@/components/maintain/MaintainDetail/hardware.vue'
import MaintainUpdate from '@/components/maintain/MaintainDetail/update.vue'
import DetailTab from '@/components/maintain/DetailTab.vue'
import { EasyMaintainCol } from '@/contants/tabCol.js'
import { getEasyMainLis, delEasyMainLis } from '@/service/help'
export default {
    name:'easymaintain',
    components:{
        Search,
        CommonTable,
        MaintainDetail,
        MaintainFile,
        MaintainHardware,
        MaintainUpdate,
        DetailTab
    },
    data(){
        return{
            tabCol:EasyMaintainCol,
            dataLis:[],
            form:{},
            // 详情
            id:0,
            tab:[
                {label:'维保',name:'MaintainDetail',active:'active'},
                {label:'文件',name:'MaintainFile',active:''},
                {label:'维修及更新记录',name:'MaintainUpdate',active:''},
                {label:'硬件管理',name:'MaintainHardware',active:''},
            ],
            currTab:'MaintainDetail',
            dialogVisible:false
        }
    },
    created(){
    },
    methods:{
        getLis(form){
            this.form = form
            getEasyMainLis(form).then(res=>{
                console.log(res)
                let {success,data} = res.data
                if(success){
                    this.dataLis = []
                    data.forEach(v => {
                        v['item_belong'] = v.item + (v.belong ? '【'+v.belong+'】' : '')
                        v['status_text'] = v.item_status === 1 ? '结束' : '正常'
                        v['status_color'] = v.item_status === 1 ? 'error' : 'success'
                        v['lately'] = v.em_up_time && v.em_app_uname ? v.em_up_time + '/'+ v.em_app_uname : ''
                        this.dataLis.push(v)
                    });
                }
            })
        },
        handleTab(obj){
           console.log(obj,'obj')
           this.currTab = obj.name
           this.tab.map(v=>{
                v.active = ''
                if(v.name == obj.name){
                    v.active = 'active'
                }
            }) 
           
        },
        handleRow(row){
            this.id = row.id
            this.dialogVisible = true
        },
        handleDel(row){
            this.$confirm('是否删除该条记录？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                showClose: false,
                type: 'warning',
                center: true,
            }).then(() => {
                delEasyMainLis({
                    id:row.id
                }).then(res=>{
                    let {success,data} = res.data
                    if(success){
                        this.$message({
                                type: 'success',
                                message: '删除成功!',
                        });
                        this.getLis(this.form)
                    }
                })
            })
            .catch(() => {
            
            });
        },
        handleSearch(form){
            this.getLis(form)
        }
    }
};
</script>
<style lang="less">
@import '~@/assets/style/maintain/easymaintain.less';

</style>


