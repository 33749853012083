<template>
<!-- 最新进度-->
  <div class="easy-comment">
     <div class="comment-lis">
        <div class="comment-item" v-for="(v,i) in commentLis" :key="'comment'+i">
            <div class="avator">{{lastName(v.uname)}}</div>
            <div class="item-content">
                <div class="item-name"><span>{{v.uname}}</span> <span>{{v.ctime}}</span></div>
                <div class="item-text">{{v.content}}</div>
            </div>
        </div>
        <div v-if="commentLis.length === 0" class="comment-empty">暂无数据</div>
     </div>
     <div class="my-comment">
        <div class="avator">
            {{lastName(uname)}}
        </div>
        <el-input v-model.trim="comment" clearable placeholder="评论内容，文字上限500（Enter发送）" @keydown.enter.native="send" class="my-comment-input"></el-input>
        <div class="send" @click="send">发送</div>
     </div>
  </div>
</template>
<script>
import cookie from '@/utils/cookie';
import { addComment } from '@/service/help'
import { dateFormat } from '@/utils/utils'
export default {
    name:'comment',
    props:{
        info:{
            type:Object,
            default:()=>{return {} }
        }
    },
    data(){
        return{
            uname:cookie.get('name'),
            id:'',
            comment:'',
            commentLis:[]
        }
    },
    created(){
        console.log(this.info,'===comment===')
        this.id = this.info.id
        this.commentLis = JSON.parse(JSON.stringify(this.info.em_comments)).reverse()
    },
    methods:{
        send(){
            if(!this.comment){
                this.$message({
                    type: 'error',
                    message: '评论内容不能为空！',
                });
                return
            }
            addComment({id:this.id,content:this.comment}).then(res=>{
                let {success,data} = res.data
                if(success){
                    this.$message({
                        type: 'success',
                        message: '评论成功！',
                    });
                    this.commentLis.unshift({uname:this.uname,content:this.comment,ctime:dateFormat()})
                    this.comment = ''
                }
            })
        }
    }

};
</script>
<style lang="less" >
    .easy-comment{
        padding: 10px 20px 0px 20px;
        height: calc(~'100% - 30px');
        box-sizing: border-box;
        
        position: relative;
        .avator{
            width: 36px;
            height: 36px;
            border-radius: 50%;
            color: #fff;
            text-align: center;
            line-height: 36px;
            font-size: 12px;
            background: rgba(70, 216, 255, 1);
            margin-right: 10px;
        }
        .comment-lis{
            height: calc(~'100% - 60px');
            overflow-y: auto;
        }
        .comment-empty{
            text-align: center;
            line-height: 50px;
            color: #999;
        }
        .comment-item{
            padding-bottom: 8px;
            border-bottom: 1px solid #E8ECEF;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin-top: 8px;
            
            .item-content{
                .item-name{
                    font-size: 12px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #8590B3;
                    margin-bottom: 5px;
                }
                .item-text{
                    font-size: 12px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #334681;
                }
            }
        }
        .my-comment{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: absolute;
            bottom: 0;
            left: 0;
            box-sizing: border-box;
            padding:  10px 20px;
            .my-comment-input{
                flex: 1;
                .el-input__inner{
                    border: 0;
                    background: #F8FBFF;
                }
               
            }
            .send{
                width: 60px;
                height: 32px;
                background: #13BFFF;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                font-size: 14px;
                text-align: center;
                line-height: 32px;
                color: #FFFFFF;
                cursor: pointer;
            }
        }
    }
</style>