<template>
  <div class="maintain-detail">
    <!-- 硬件 -->
      <div class="search">
          <el-input v-model.trim="key" class="key-input" placeholder="搜索" prefix-icon="el-icon-search" clearable @clear="getHardwaresLis" @keydown.enter.native="getHardwaresLis"></el-input>
          <el-button type="primary" @click="handleAdd">新增</el-button>
      </div>
      <div class="file-table">
          <common-table
            cname="hardware"
            :tabCol="tabCol"
            :dataLis="dataLis"
            @handleEdit="handleEdit"
            @handleDel="handleDel"
        ></common-table>
      </div>
      <!-- t弹窗 -->
      <el-dialog
          :visible.sync="dialogVisible"
          width="300"
          :title="dialogTitle"
          :append-to-body="true"
      >
          <form-edit :FormCol="FormCol" :pform="pform" v-if="dialogVisible" @handleCancel="dialogVisible = false" @handleSave="handleSave"></form-edit>
      </el-dialog>
  </div>
</template>
<script>
import CommonTable from '@/components/maintain/CommonTable.vue';
import FormEdit from './sub/formEdit.vue';
import { EasyMainHardwareCol,HardwareStatusOptions } from '@/contants/tabCol.js'
import { addHardware,delHardware,modHardware,getHardwareLis} from '@/service/help'
export default {

    name:'maintain-hardware',
    components:{
      CommonTable,
      FormEdit
    },
    props:{
      id:Number
    },
    data(){
        return{
            key:'',
            tabCol:EasyMainHardwareCol,
            dataLis:[],
            // 弹窗
            dialogTitle:'新建硬件',
            dialogVisible:false,
            FormCol:[
              {label:'硬件名称',props:'hardware',type:'input',placeholder:'请输入硬件名称'},
              {label:'购买时间',props:'buy_time',type:'date',placeholder:'请选择购买日期'},
              {label:'年限',props:'uselimit',type:'unit-input',placeholder:'请输入已使用年限',unit:'年'},
              {label:'状态',props:'status',type:'select',placeholder:'请选择状态',option:HardwareStatusOptions},
              {label:'备注',props:'remarks',type:'input',placeholder:'请输入备注'},
              {label:'上传文件',props:'files',type:'arr-upload',placeholder:'（仅支持PPT、PDF、word、jpg、png格式文件）'},
            ],
            pform:{
              hardware:'',
              buy_time:'',
              uselimit:'',
              status:'使用中',
              remarks:'',
              files:[]
            }
        }
    },
    created(){
      this.getHardwaresLis()
    },
    methods:{
      handleEdit(row){
        this.dialogTitle = '编辑硬件'
        this.pform = Object.assign(this.pform,{...row,files:row.picture,uselimit:row.uselimit.replace('年','')})
        this.dialogVisible = true
      },
      handleDel(row){
        this.$confirm('是否删除该条记录？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          showClose: false,
          type: 'warning',
          center: true,
        })
        .then(() => {
          delHardware({
            id:row.id
          }).then(res=>{
            let {success,data} = res.data
            if(success){
              this.$message({
                    type: 'success',
                    message: '删除成功!',
              });
              this.getHardwaresLis()
            }
          })
        })
        .catch(() => {
         
        });
      },
      getHardwaresLis(){
        getHardwareLis({
          id:this.id,
          key:this.key
        }).then(res=>{
          let {success,data} = res.data
          if(success){
            this.dataLis = data.map(v=>{
              v['status_text'] = v.status 
              v['status_color'] = ['使用中'].includes(v.status) ?   'success' : ['备用'].includes(v.status) ? '' : 'error'
              return v
            })
          }
        })
      },
      handleSave(data){
        console.log(data,'====data===')
        let url = this.dialogTitle == '新建硬件' ? addHardware : modHardware
        url({
          id:this.dialogTitle == '新建硬件' ? this.id : this.pform.id,
          ...data,
          picture:data.files,
          uselimit:data.uselimit + '年'
        }).then(res=>{
          let {success,data} = res.data
          if(success){
            this.$message({
                  type: 'success',
                  message: '保存成功!',
            });
            this.dialogVisible = false
            this.getHardwaresLis()
          }
        })
      },
      handleAdd(){
        this.dialogTitle = '新建硬件'
        this.pform = {
          hardware:'',
          buy_time:'',
          uselimit:'',
          status:'使用中',
          remarks:'',
          files:[]
        }
        this.dialogVisible = true
      }

    }

};
</script>
<style lang="less">
@import '~@/assets/style/maintain/maintaindetail.less';
</style>