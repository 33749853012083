
<template>
  <div class="progress-track">
        <div class="track-tab">
                <span :class="[{'track-active':operateKey == v.key}]" v-for="(v,i) in operate" :key="'operate'+i" @click="handleTab(v.key)"><img :src="operateKey == v.key ? v.activeIco : v.ico" alt="">{{v.str}}</span>
        </div>
        <component :is="operateKey" :info="curr_info" :key="new Date().getTime()"></component>
  </div>
</template>
<script>
import comment from '@/components/common/comment.vue'
import trajectory from '@/components/common/trajectory.vue'
import { getEasyMainInfo } from '@/service/help'
export default {
    name:'progress-track',
    props:{
        info:Object
    },
    data(){
        return{
            operate: [
                {
                    key: 'comment',
                    str: '最新进度',
                    ico: require('../../../../assets/image/icon-progress/icon_pl@2x.png'),
                    activeIco: require('../../../../assets/image/icon-progress/icon_pl@2x(1).png'),
                },
                {
                    key: 'trajectory',
                    str: '操作轨迹',
                    ico: require('../../../../assets/image/icon-progress/icon_czgj@2x.png'),
                    activeIco: require('../../../../assets/image/icon-progress/icon_czgj@2x(1).png'),
                },
            ],
            operateKey: 'comment',
            curr_info:{}
        }
    },
    components:{
        comment,
        trajectory
    },
    watch:{
        info:{
            handler(newVal,oldVal){
                console.log(newVal,'progress')
                this.curr_info = JSON.parse(JSON.stringify(newVal))
            },
            deep:true
        }
    },
    created(){
        console.log(this.info,'======pro')
        this.curr_info = JSON.parse(JSON.stringify(this.info))
    },
    methods:{
        handleTab(key){ // 刷新info
            getEasyMainInfo({id:this.info.id}).then(res=>{
                let {success,data} = res.data
                if(success){
                    this.curr_info = data
                    console.log(this.curr_info,'=======this.curr_info')
                    this.operateKey = key
                }
            })
            
        }
    }

};
</script>
<style lang="less">
    .progress-track{
        flex: 4;
        min-height: 260px;
        max-height: 260px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #E8ECEF;
        .track-tab{
            padding: 10px 30px 0 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            span{
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-right: 20px;
                cursor: pointer;
                &.track-active{
                    color: rgba(19, 191, 255, 1);
                }
                img{
                    width: 16px;
                    margin-right: 5px;
                }
            }
        }
    }
</style>


