<template>
  <div class="add-project">
        <el-form ref="form" :model="form" label-width="120px">
            <el-form-item label="医院：">
                <el-input v-model.trim="form.item" class="key-input" placeholder="请填写医院名称"  clearable ></el-input>
                <el-autocomplete
                    class="autocomplete-input marginleft"
                    v-model="form.belong"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入内容"
                    @select="handleSelect"
                ></el-autocomplete>
            </el-form-item>

            <el-form-item label="开始时间：">
                <el-date-picker
                    v-model="form.start_time"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择开始日期"
                    @change="changeDate('stime')">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="结束/续约时间">
                <el-date-picker
                    v-model="form.end_time"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择结束日期"
                    @change="changeDate('etime')"
                >
                </el-date-picker>
            </el-form-item>

            <el-form-item label="现场联系人：">
                <el-input v-model.trim="form.witness" class="select-input" placeholder="姓名"  clearable ></el-input>
                <el-input v-model.trim="form.witness_phone" class="key-input marginleft" placeholder="电话"  clearable ></el-input>
            </el-form-item>

            <el-form-item label="维保责任人：">
                <el-input v-model.trim="form.owner" class="select-input" placeholder="姓名"  clearable ></el-input>
                <el-input v-model.trim="form.owner_phone" class="key-input marginleft" placeholder="电话"  clearable ></el-input>
            </el-form-item>

            <el-form-item label="">
                <div class="add-btns">
                    <el-button type="primary" @click="handleSubmit">确定</el-button>
                    <el-button @click="handleCancel">取消</el-button>
                </div>
                
            </el-form-item>
        </el-form>
  </div>
</template>
<script>
import { checkEndDate } from '@/utils/utils'
import { getBelongLis } from '@/service/help'
export default {
    name:'addproject',
    data(){
        return{
            projectOptions:[],
            form:{
                item:'',
                belong:'',
                start_time:'',
                end_time:'',
                witness_phone:'', // 现场联系人
                witness:'',
                owner:'', // 维保联系人
                owner_phone:''
            }
        }
    },
    methods:{
        handleSelect(e){
            console.log(e,'====e===')
            this.form.belong = e.value
        },
        querySearch(queryString, cb){
            getBelongLis().then(res => {
                if(res.data.success) {
                    this.projectOptions = res.data.data.map(v => {
                        return {
                            value: v,
                            address: v
                        }
                    })
                    this.queryData(queryString, cb);
                }
            })
        },
        queryData(queryString, cb){
            var arr = this.projectOptions;
            var results = queryString ? arr.filter(this.createStateFilter(queryString)) : arr;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                cb(results);
            }, 300);
        },
        createStateFilter(queryString) {
            return (state) => {
                return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        changeDate(str){
            if(checkEndDate(this.form.start_time,this.form.end_time)){
                this.$message({
                    type: 'error',
                    message: '开始日期不能大于结束日期！',
                });
                this.form.start_time = ''
                return
            }
        },
        handleCancel(){
            this.$emit('handleCancel')
        },
        handleSubmit(){
            this.$emit('handleSubmit',this.form)
        }
    }

};
</script>
<style lang="less">
    .add-project{
        .select-input{
            width: 120px;
        }
        .autocomplete-input{
            width: 240px;
        }
        .key-input{
            flex: 1;
            
        }
        .el-form-item__content{
            display: flex;
            align-items: center;
            justify-content: center;
            
        }
        .el-date-editor{
            width: 100%;
        }
        .el-form-item{
            margin-bottom: 20px;
        }
        .marginleft{
            margin-left: 10px;
        }
        .marginright{
            margin-right: 10px;
        }
        .add-btns{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }
</style>