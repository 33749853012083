<style lang="css">
.picture-pop {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 90%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  transform: translate(-50%,-50%);
  z-index: 9999 !important;
}
.picture-pop img{
  max-width: 100%;
  max-height: 100%;
}

.picture--mini {
  display: inline-block;
  margin: 3px;
}

.picture--mini {
  width: 40px;
  height: 40px;
}
</style>
<template>
  <div id="picture" class="picture">
    <img
      :src="urls"
      @click="handleShowImgPop"
    >
  </div>
</template>

<script>
export default {
  name: "ThePicture",
  props: {
    urls: String,
    canPreview: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    handleShowImgPop(e) {
      if (this.urls) {
        if(this.canPreview) {
          this.handleImgPop(this.urls);
        }else{
          this.$emit('handle-click', e, this.urls)
        }
      }
    },
    handleImgPop(url) {
      let div = document.createElement("div");
      div.innerHTML =
        '<div class="picture-pop"><img class="picture" src="' +
        url +
        '"></div>';
      document.body.appendChild(div);
      let pnode = document.querySelector(".picture-pop");
      // pnode.addEventListener("click", function () {
      //   pnode.remove();
      // });
    },
  },
  components: {},
};
</script>
