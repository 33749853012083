<template>
  <div class="easy-remind">
    <div class="remind-top">
        <h6>提醒</h6>
        <div class="add-remind" @click="addRemind"><i class="el-icon-plus"></i>新增</div>
    </div>
    <div class="remind-table">
        <common-table
            cname="remind"
            :tabCol="tabCol"
            :dataLis="dataLis"
        ></common-table>
    </div>

    <el-dialog
      width="300"
      title="新建提醒"
      :visible.sync="addVisible"
      append-to-body>
      <div class="add-remind-dialog" @click="showCharge = false">
         <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="提醒人">
                <div class="form-item-left">
                    <el-input v-model="form.name" clearable placeholder="请输入姓名" class="remind-input"></el-input>
                    <el-input v-model="form.phone" clearable placeholder="请输入手机号" class="remind-input"></el-input>
                </div>
                <div class="form-item-right">
                    <el-button @click.stop="showCharge = true">选择用户</el-button>
                    <div @click="$event.stopPropagation()" v-if="showCharge" class="showCharge">
                        <member :userType="'all'"  @pickit="pickit" />
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="提醒时间">
                <el-date-picker
                    v-model="form.ttime"
                    type="datetime"
                    clearable
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm"     
                    placeholder="提醒时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="内容">
                <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="form.content">
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submit">确定</el-button>
                <el-button @click="addVisible = false">取消</el-button>
            </el-form-item>
         </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import CommonTable from '@/components/maintain/CommonTable.vue';
import member from '@/components/task/progress/member';
import { EasyRemindCol } from '@/contants/tabCol'
import { addremind,getEasyMainInfo } from '@/service/help'
export default {
    name:'remind',
    components: { CommonTable,member },
    props:{
        info:Object
    },
    data(){
        return{
            tabCol:EasyRemindCol,
            dataLis:[],
            // 新建
            addVisible:false,
            form:{
                content:'',
                ttime:'',
                name:'',
                phone:''
            },
            // 选择人员
            showCharge:false
        }
    },
    created(){
        console.log(this.info,'=====remind this.info')
        this.dataLis = JSON.parse(JSON.stringify(this.info.reminds))
        this.dataLis.forEach(v=>{
            v['status_text'] = v.status === 0 ? '未发送' : '已发送'
            v['status_color'] = v.status === 0 ? 'warning' : 'success'
        })
    },
    methods:{
        getRemindLis(){
            getEasyMainInfo({id:this.info.id}).then(res=>{
                let {success,data} = res.data
                if(success){
                    this.dataLis = data.reminds
                    this.dataLis.forEach(v=>{
                        v['status_text'] = v.status === 0 ? '未发送' : '已发送'
                        v['status_color'] = v.status === 0 ? 'warning' : 'success'
                    })
                }
            })
        },
        submit(){
            console.log({
                "id": this.info.id,
                "re_time": this.form.ttime,
                "re_phone": this.form.phone,
                "re_uname": this.form.name,
                "message": this.form.content,
            })
            addremind({
                "id": this.info.id,
                "re_time": this.form.ttime,
                "re_phone": this.form.phone,
                "re_uname": this.form.name,
                "message": this.form.content,
            }).then(res=>{
                let {success,data} = res.data
                if(success){
                    this.$message({
                        type: 'success',
                        message: '新增成功！',
                    });
                    this.addVisible = false
                    this.getRemindLis()
                }
            })
        },
        pickit(item){
            console.log(item,'pickname,lis')
            this.form.name = item.name
            this.form.phone = item.phone
            this.showCharge = false
        },
        addRemind(){
            this.form = {
                content:'',
                ttime:'',
                name:'',
                phone:''
            }
            this.addVisible = true
        }
    }

};
</script>
<style lang="less">
    .easy-remind{
        flex: 2;
        max-height: 340px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #E8ECEF;
        padding: 8px 16px;
        margin-right: 16px;
        .remind-top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding:  0;
            .add-remind{
                cursor: pointer;
                color: rgba(19, 191, 255, 1);
            }
        }
        .remind-table{
            margin-top: 5px;
            height: calc(~'100% - 20px');
        }
    }
    .add-remind-dialog{
        .remind-input{
            width: 200px;
            margin-right: 20px;
        }
        .el-form-item__content{
            display: flex;
            align-items: center;
        }
    }
    
</style>