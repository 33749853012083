<template>
  <div class="maintain-detail">
      <div class="search">
          <el-input v-model.trim="key" class="key-input" placeholder="搜索" prefix-icon="el-icon-search" clearable @clear="getFilesLis" @keydown.enter.native="getFilesLis"></el-input>
          <el-button type="primary" @click="handleAdd">新增</el-button>
      </div>
      <div class="file-table">
          <common-table
            cname="remind"
            :tabCol="tabCol"
            :dataLis="dataLis"
            @handleEdit="handleEdit"
            @handleDel="handleDel"
        ></common-table>
      </div>

      <!-- t弹窗 -->
      <el-dialog
          :visible.sync="dialogVisible"
          width="300"
          :title="dialogTitle"
          :append-to-body="true"
      >
          <form-edit :FormCol="FormCol" :pform="pform" v-if="dialogVisible" @handleCancel="dialogVisible = false" @handleSave="handleSave"></form-edit>
      </el-dialog>
  </div>
</template>
<script>
import CommonTable from '@/components/maintain/CommonTable.vue';
import FormEdit from './sub/formEdit.vue';
import { EasyMainFileCol } from '@/contants/tabCol.js'
import { addFile,modFile,delFile,getFileLis } from '@/service/help'
export default {

    name:'maintain-file',
    components:{
      CommonTable,
      FormEdit
    },
    props:{
      id:Number
    },
    data(){
        return{
            key:'',
            tabCol:EasyMainFileCol,
            dataLis:[],
            // 弹窗
            dialogTitle:'新建文件',
            dialogVisible:false,
            FormCol:[
              {label:'名称',props:'filename',type:'input',placeholder:'请输入文件名称'},
              {label:'上传文件',props:'files',type:'arr-upload',placeholder:'（仅支持PPT、PDF、word、jpg、png格式文件）'},
              {label:'备注',props:'remarks',type:'textarea',placeholder:'请输入备注'}
            ],
            pform:{
              filename:'',
              files:[],
              remarks:''
            }
        }
    },
    created(){
      this.getFilesLis()
    },
    methods:{
      handleEdit(row){
        this.dialogTitle = '编辑文件'
        this.pform = Object.assign(this.pform,{...row,files:row.picture})
        this.dialogVisible = true
      },
      handleDel(row){
        this.$confirm('是否删除该条记录？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          showClose: false,
          type: 'warning',
          center: true,
        })
        .then(() => {
          delFile({
            id:row.id
          }).then(res=>{
            let {success,data} = res.data
            if(success){
              this.$message({
                    type: 'success',
                    message: '删除成功!',
              });
              this.getFilesLis()
            }
          })
        })
        .catch(() => {
         
        });
      },
      getFilesLis(){
        getFileLis({
          id:this.id,
          key:this.key
        }).then(res=>{
          let {success,data} = res.data
          if(success){
            this.dataLis = data
          }
        })
      },
      handleSave(data){
        console.log(data,'====data===')
        let url = this.dialogTitle == '新建文件' ? addFile : modFile
        url({
          id:this.dialogTitle == '新建文件' ? this.id : this.pform.id,
          ...data,
          picture:data.files
        }).then(res=>{
          let {success,data} = res.data
          if(success){
            this.$message({
                  type: 'success',
                  message: '保存成功!',
            });
            this.dialogVisible = false
            this.getFilesLis()
          }
        })
      },
      handleAdd(){
        this.dialogTitle = '新建文件'
        this.pform = {
          filename:'',
          files:[],
          remarks:''
        }
        this.dialogVisible = true
      }
    }

};
</script>
<style lang="less">
@import '~@/assets/style/maintain/maintaindetail.less';
</style>