<template>
    <div class="common-table">
        <el-table
            :data="dataLis"
            border
            style="width: 100%"
            height="100%"
            @row-dblclick="handleRow"
        >
            <el-table-column
                type="index"
                width="80"
                label="序号"
                align="center"
                v-if="isNum"
            >
            </el-table-column>
            <el-table-column
                v-for="(v,i) in tabCol"
                :key="'tabcol'+i"
                :prop="v.prop"
                :label="v.label"
                :width="v.width"
                :sortable="v.isSort"
                :align="v.align"
            >
                <template slot-scope="scope">
                    <div v-if="v.prop == 'status_text'">
                        <span :class="[scope.row['status_color']]">{{scope.row[v.prop]}}</span>
                    </div>
                    <div v-else-if="v.type == 'files'">
                        <!-- 文件列表 -->
                        <span v-for="(file,findex) in scope.row[v.prop]" :key="'files'+findex" class="files-span">{{file.name}}</span>
                    </div>
                    <div v-else-if="v.type == 'btns'">
                        <!-- 按钮列表 -->
                        <div class="btns-box" >
                            <i class="el-icon-edit btns-icon" v-if="v.btns.includes('edit')" @click="handleEdit(scope.row)"></i>
                            <i class="el-icon-delete btns-icon" v-if="v.btns.includes('del')" @click="handleDel(scope.row)"></i>
                        </div>
                    </div>
                    <span v-else>{{scope.row[v.prop]}}</span>
                </template>
            </el-table-column>

            <template #empty>
                <el-empty description="暂无数据"></el-empty>
            </template>
        </el-table>
    </div>

</template>


<script>
export default {
    name:'emabletable',
    props:{
        cname:String,
        isNum:{
            type:Boolean,
            default:false
        },
        tabCol:{
            type:Array,
            default:()=>[]
        },
        dataLis:{
            type:Array,
            default:()=>[]
        },
        btns:{
            type:Array,
            default:()=>[]
        },
    },
    data(){
        return{

        }
    },
    methods:{
        handleRow(row){
            this.$emit('handleRow',row)
        },
        handleEdit(row){
            this.$emit('handleEdit',row)
        },
        handleDel(row){
            this.$emit('handleDel',row)
        }
    }
}
</script>

<style lang="less">
    .common-table{
        height: 100%;
        tr th{
            background: #F5F7FA !important;
            color: #333333;
        }
        .success{
            color: rgba(30, 228, 142, 1);
        }
        .error{
            color:rgba(255, 96, 87, 1);
        }
        .warning{
            color: rgba(255, 193, 8, 1);
        }
        .btn{
            padding: 7px 14px;
            display: inline-block;
            font-size: 14px;
            margin: 0 10px;
            cursor: pointer;
        }
        .edit-btn{
            border: 1px solid #409EFF;
            color: #409EFF;
            
        }
        .del-btn{
            border: 1px solid #F56C6C;
            color: #F56C6C;
        }
        .files-span{
            cursor: pointer;
            color: #13bfff;
            padding-right: 15px;
            padding-bottom: 10px;
            &:hover{
                text-decoration: underline;
            }
        }
        .btns-box{
            display: flex;
            align-items: center;
            justify-content: center;
            .btns-icon{
                font-size: 20px;
                cursor: pointer;
                color: rgba(194, 199, 217, 1);
                margin-right: 20px;
            }
        }
        .emrecord{
            cursor: pointer;
            color: #13bfff;
        }
    }
</style>
