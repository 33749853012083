<template>
    <div class="common-table">
        <el-table
            :data="dataLis"
            border
            style="width: 100%"
            height="100%"
            @row-dblclick="handleRow"
        >
            <el-table-column
                type="index"
                width="80"
                label="序号"
                align="center"
                v-if="isNum"
            >
            </el-table-column>
            <el-table-column
                v-for="(v,i) in tabCol"
                :key="'tabcol'+i"
                :prop="v.prop"
                :label="v.label"
                :width="v.width"
                :sortable="v.isSort"
                :align="v.align"
            >
                <template slot-scope="scope">
                    <div v-if="v.prop == 'status_text'">
                        <span :class="[scope.row['status_color']]">{{scope.row[v.prop]}}</span>
                    </div>
                    <div v-else-if="v.type == 'files'">
                        <!-- 文件列表 -->
                        <span v-for="(file,findex) in scope.row[v.prop]" :key="'files'+findex" class="files-span" @click="handleFile(file.url)">{{file.name}}</span>
                    </div>
                    <div v-else-if="v.type == 'btns'">
                        <!-- 按钮列表 -->
                        <div class="btns-box" >
                            <i class="el-icon-edit btns-icon" v-if="v.btns.includes('edit')" @click="handleEdit(scope.row)"></i>
                            <i class="el-icon-delete btns-icon" v-if="v.btns.includes('del')" @click="handleDel(scope.row)"></i>
                        </div>
                    </div>
                    <div v-else-if="v.type == 'showPictrue'">
                        <div class="pictrue-box" >
                            <img class="pictrue-img"  :src="scope.row[v.prop][0]" v-if="scope.row[v.prop].length>0" @click="handlePreView(scope.row[v.prop])"/>

                        </div>
                    </div>
                    <el-popover
                        v-else-if="v.type == 'embedded-table'"
                        placement="left"
                        width="600"
                        trigger="click">
                        <EmableTable
                            :tabCol="v.col"
                            :dataLis="scope.row[v.prop]"
                        ></EmableTable>
                        <span slot="reference" class="emrecord">{{scope.row[v.prop].length}}</span>
                    </el-popover>
                    <div v-else-if="v.type == 'copyToken'">
                        <span class="copy-token"  @click="handleCopy(scope.row[v.prop])" v-if="!scope.row['status']">复制 <br/> 链接</span>
                    </div>
                    <span v-else>{{scope.row[v.prop]}}</span>
                </template>
            </el-table-column>

            <template #empty>
                <el-empty description="暂无数据"></el-empty>
            </template>
        </el-table>


        
    </div>

</template>


<script>
import EmableTable from '@/components/maintain/EmableTable.vue';
import ThePicture from '@/components/common/ThePicture.vue';
import { Message } from 'element-ui';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
Vue.use(VueViewer)

export default {
    name:'commontable',
    props:{
        cname:String,
        isNum:{
            type:Boolean,
            default:false
        },
        tabCol:{
            type:Array,
            default:()=>[]
        },
        dataLis:{
            type:Array,
            default:()=>[]
        },
        btns:{
            type:Array,
            default:()=>[]
        },
    },
    components:{
      EmableTable,
      ThePicture
    },
    data(){
        return{

        }
    },
    methods:{
        handlePreView(imgs){
           this.$viewerApi({
             images: imgs,
           })
        },
        handleCopy(token){
            console.log(token,'===token')
            this.$copyText(token).then(function(){
                Message({
                    type: 'success',
                    message: '内容已复制到剪切板！',
                });
            }, function(e) {
                Message({
                    type: 'error',
                    message: '复制错误！',
                });
            });
        },
        handleFile(url){
            window.open(url, "_blank")
        },
        handleRow(row){
            this.$emit('handleRow',row)
        },
        handleEdit(row){
            this.$emit('handleEdit',row)
        },
        handleDel(row){
            this.$emit('handleDel',row)
        }
    }
}
</script>

<style lang="less">
    .common-table{
        height: 100%;
        tr th{
            background: #F5F7FA !important;
            color: #333333;
        }
        .success{
            color: rgba(30, 228, 142, 1);
        }
        .error{
            color:rgba(255, 96, 87, 1);
        }
        .warning{
            color: rgba(255, 193, 8, 1);
        }
        .btn{
            padding: 7px 14px;
            display: inline-block;
            font-size: 14px;
            margin: 0 10px;
            cursor: pointer;
        }
        .edit-btn{
            border: 1px solid #409EFF;
            color: #409EFF;
            
        }
        .del-btn{
            border: 1px solid #F56C6C;
            color: #F56C6C;
        }
        .files-span{
            cursor: pointer;
            color: #13bfff;
            padding-right: 15px;
            padding-bottom: 10px;
            &:hover{
                text-decoration: underline;
            }
        }
        .btns-box{
            display: flex;
            align-items: center;
            justify-content: center;
            .btns-icon{
                font-size: 20px;
                cursor: pointer;
                color: rgba(194, 199, 217, 1);
                margin-right: 20px;
            }
        }
        .emrecord{
            cursor: pointer;
            color: #13bfff;
        }
        .pictrue-img{
            width: 45px;
            height: 45px;
            float: left;
            margin: 0 10px 10px 0;
            cursor: pointer;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .copy-token{
            color: #13BFFF;
            cursor: pointer;
        }
        .pictrue-box{
            &::after{
                content: '';
                display: block;
                clear: both;
            }
        }
    }
</style>
