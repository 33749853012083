<template>
  <div name="member" v-if="list.length>0">
    <div class="member-main" id="box" :style="{ top: initTop,left:initLeft, 'z-index': zindex }">
      <div class="headnav">
        <!-- <span>团队</span>|
        <span>群组</span> -->
      </div>
      <div class="searchbox">
        <img :src="searchicon" alt />
        <input type="text" v-model="modelstr" placeholder="搜索成员..." />
        <!--@input="search"-->
      </div>
      <div @click="ctrl = !ctrl" class="listtitle">
        <img :src="openlisticon" alt v-show="ctrl" />
        <img :src="closelisticon" alt v-show="!ctrl" />
        <h4 v-if="modelstr === ''">全部联系人({{ list.length }}人)</h4>
        <h4 v-else>查找到以下联系人({{ res.length }}人)</h4>
      </div>
      <div v-show="ctrl" class="list">
        <ul v-if="modelstr !== ''">
          <li
            :class="{ listcolor: listColor === item.id, select: isExit(item.id) >= 0 }"
            @mouseenter="listColor = item.id"
            @mouseleave="listColor = 0"
            v-for="item in res"
            :key="item.id"
            @click.stop="pickit(item)"
          >
            <!--            v-for="item in liststr"-->
            <div class="default-avatar" v-if="item.avatar == 'https://oss.eai9.com/210120/5a2296c509b67333f6a81bee7cc60b13.png' || item.avatar == ''">{{ lastName(item.name) }}</div>
            <img :src="item.avatar" :alt="lastName(item.name)" v-else/>
            <span>{{ item.name }}</span>
          </li>
        </ul>
        <ul v-else-if="modelstr === ''">
          <li
            v-for="item in list"
            :key="item.id"
            :class="{ listcolor: listColor === item.id, select: isExit(item.id) >= 0 }"
            @click.stop="pickit(item)"
            @mouseenter="listColor = item.id"
            @mouseleave="listColor = 0"
          >
            <!-- <div>{{ lastName(item.name) }}</div> -->
            <div class="default-avatar" v-if="item.avatar == 'https://oss.eai9.com/210120/5a2296c509b67333f6a81bee7cc60b13.png' || item.avatar == ''">{{ lastName(item.name) }}</div>
            <img :src="item.avatar" :alt="lastName(item.name)" v-else/>
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { lastName } from '@/utils/utils';
import { postTaskUser,getDepartUserLis } from '@/service/help';
export default {
  name: 'member',
  data() {
    return {
      test: false,
      openlisticon: require('@/assets/image/progress/addTask/openlist.png'),
      closelisticon: require('@/assets/image/progress/addTask/closelist.png'),
      searchicon: require('@/assets/image/progress/addTask/search.png'),
      ctrl: true,
      list: [],
      modelstr: '',
      /*      liststr: [],*/
      listColor: 0,
      pickname: '',
      initTop: 'unset',
      initLeft:'unset',
      pickId: [],
      pickData: [],
      containerWidth:300, // 成员容器的宽高
      containerHeight:380
    };
  },
  props: {
    id: {
      //传任务id，可以查看剩余参与人，不传默认查询全部
      type: String,
      default: '',
    },
    sector_id:{ // 部门id
      type:String,
      default:''
    },
    position_top:{ // 容器是否在父元素里显示 unset 有容器包着显示 no-unset 按坐标显示
      type:String,
      default:'unset'
    }, 
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    clickBoxHeight:{ // 点击容器的高度
      type: Number,
      default: 0,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    currArr: {
      type: Array,
      default() {
        return [];
      },
    },
    zindex: {
      type: Number,
      default: 10001,
    },
    userType:{ // 是获取任务人员还是部门下的人员
      type:String,
      default:'all'
    }
  },
  mounted() {},
  created() {
    let url = this.userType == 'all' ? postTaskUser : getDepartUserLis
    let data = {}
    if(this.userType == 'all'){
      data.id = this.id.toString()
    }else{
      data.sector_id = this.sector_id
    }
    url(data).then(res => {
      let data = res.data.data;
      this.list = data;
      this.$set(this, 'pickId', [...this.currArr]); //要求深拷贝、响应式
      console.log(this.pickId,'this.pickId')
      this.currArr.forEach(v => {
        this.pickData.push(data.find(v1 => v1.id == v));
      });
    });
  },
  beforeUpdate() {
    if(this.position_top == 'unset'){
      this.initLeft = this.initTop = 'unset';
    }else{
      if(document.body.clientWidth - this.x >= this.containerWidth){
        this.initLeft = this.x + 'px'
      }else{
        this.initLeft = this.x - this.containerWidth + 'px'
      }
      if(document.body.clientHeight - this.y >= this.containerHeight){ // 下方位置
        this.initTop = this.clickBoxHeight + this.y + 'px'
      }else if(this.y >= this.containerHeight ){ // 上方位置
        this.initTop = this.y -  this.containerHeight + 'px'
      }else{ // 中间位置
        this.initTop = '30%'
      }
    }
  },
  watch:{
    // currArr:{
    //   handler(){
    //     console.log(111)
    //   },
    //   deep:true
    // }
  },  
  methods: {
    pickit(item) {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].id === this.listColor) {
          this.pickname = this.list[i].name;
        }
      }
      let id = this.listColor;
      let name = this.pickname;
      if (this.multiple) {
        let i = this.pickId.indexOf(id);
        if (i < 0) {
          this.pickId.push(id);
          this.pickData.push({ name, id });
        } else {
          this.pickId.splice(i, 1);
          this.pickData.splice(i, 1);
        }
        this.$emit('memberList', this.pickData, { name, id });
        // this.$emit('memberList', this.pickname, this.listColor);
      } else {
        this.$emit('fromMember', this.pickname, this.listColor);
      }
      this.$emit('pickit', item);
    },
    lastName(name) {
      return name.charAt(name.length - 2) + name.charAt(name.length - 1);
    },
  },
  computed: {
    isExit() {
      return id => {
        
        let i = this.pickId.indexOf(id);
        return i;
      };
    },
    res() {
      return this.list.filter(
        function (modelstr) {
          return modelstr.name.indexOf(this.modelstr) >= 0 || modelstr.phone.indexOf(this.modelstr) >= 0;
        }.bind(this),
      );
    },
  },
};
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
  width: 4px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 3px;
  // background-color: transparent;
  background-color: #eff3f6;
  height: 489px;
  margin: 10px 0 10px 0;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: none;
  background-color: #c2c7d9;
}
.member-main {
  line-height: normal;
  z-index: 20;
  position: fixed;
  width: 266px;
  height: 360px;
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(51, 70, 129, 0.16);
  border-radius: 4px;
  font-size: 14px;
  .headnav {
    margin: 16px 0 16px 0;
    span {
      margin: 0 20px 0 20px;
    }
  }
  .searchbox {
    width: 228px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #e8ecef;
    border-radius: 4px;
    margin-left: 20px;
    img {
      margin: 10px 0 10px 8px;
      float: left;
    }
    input {
      border: none;
      margin: 7px 0 0 8px;
      width: 190px;
    }
  }
  .listtitle {
    width: 240px;
    height: 20px;
    margin: 16px 0 0 20px;
    img {
      float: left;
      margin: 8px 5px 0 0;
    }
    h4 {
      float: left;
    }
  }
  .list {
    //   background-color: hotpink;
    width: 228px;
    height: 255px;
    margin: 8px 0 0 20px;
    overflow: auto;
    ul {
      list-style: none;
      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 220px;
        height: 36px;
        padding: 4px 0 0 5px;
        border-radius: 4px;
        cursor: pointer;
        .default-avatar {
          width: 30px;
          height: 30px;
          background: #46d8ff;
          border-radius: 50%;
          color: #ffffff;
          text-align: center;
          opacity: 1;
          font-size: 12px;
          margin-right: 5px;
          line-height: 30px;
        }
        img {
          vertical-align: middle;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 5px;
        }
        span{
          display: block;
          padding-left: 5px;
        }
      }
      .listcolor {
        background: #f2f9ff;
      }
      .select {
        position: relative;
        color: #409eff;
        font-weight: 700;
        cursor: pointer;
      }
      .select:after {
        font-family: element-icons;
        position: absolute;
        right: 20px;
        content: '\e6da';
        font-size: 12px;
        font-weight: 700;
        -webkit-font-smoothing: antialiased;
      }
    }
  }
}
</style>
