<template>
  <div class="memorandum">
      <h6>备忘录</h6>
      
      <textarea
            @blur="handleBlur"
            class="mem-textare"
            v-if="isEdit"
            v-model="text"
            ref="memorandum"
       />
       <p v-else @dblclick="showEdit" v-html="text ? htmlP2Br(escape2Html(text)) : '请双击编辑'"></p>
  </div>
</template>
<script>
import { modEasyMainNote } from '@/service/help'
export default {

    name:'maintain-detail',
    props:{
        info:Object
    },
    components:{
    },
    data(){
        return{
            text:'',
            id:'',
            isEdit:false
        }
    },
    created(){
        this.text = this.info.note 
        this.id = this.info.id
    },
    methods:{
        handleBlur(){
            modEasyMainNote({id:this.id,note:this.text}).then(res=>{
                let {success,data} = res.data
                if(success){
                    this.$message({
                        type: 'success',
                        message: '编辑成功！',
                    });
                    this.isEdit = false
                }
            })
            
        },
        showEdit(){
            this.isEdit = true
            this.$nextTick(() => {
                this.$refs.memorandum.focus();
            });
            
        },
        escape2Html(str){
            if(str){
                const arrEntities = { lt:'<',gt:'>',nbsp:' ',amp:'&',quot:'"' }
                return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) { return arrEntities[t] }) 
            }
        },
        htmlP2Br (str) {
            if (str) {
                return str.replace(/\n/ig, '<br>')
            }
        }
    }

};
</script>
<style lang="less">
    .memorandum{
        flex: 2;
        max-height: 260px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #E8ECEF;
        padding: 8px 16px;
        margin-right: 16px;
        
        p{
            margin-top: 16px;
            width: 100%;
            height: calc(~'100% - 40px');
            overflow-y: auto;
            cursor: pointer;
        }
        .mem-textare{
            margin-top: 16px;
            width: 100%;
            height: calc(~'100% - 40px');
            resize:none;
            border-color: #13bfff;
            box-sizing: border-box;
            padding: 5px;
        }
    }
</style>