<template>
  <div class="maintain-detail" v-if="flag">
      <DetailEdit :info="info" ></DetailEdit>
      <div class="maintain-main">
         <Memorandum :info="info"></Memorandum>
         <Progresstrack :info="info" ></Progresstrack>
      </div>
      <div class="footer">
        <Remind :info="info"></Remind>
        <main-record :info="info"></main-record>
      </div>
  </div>
</template>
<script>
import DetailEdit from './sub/detailEdit.vue'
import Memorandum from './sub/memorandum.vue'
import Progresstrack from './sub/progresstrack.vue';
import Remind from './sub/remind.vue'
import MainRecord from './sub/mainRecord.vue'
import { getEasyMainInfo } from '@/service/help'
export default {
    name:'maintain-detail',
    components:{
      DetailEdit,
      Memorandum,
      Progresstrack,
      Remind,
      MainRecord
    },
    props:{
      id:Number
    },
    data(){
        return{
            info:{},
            flag:false
        }
    },
    watch:{
      id:{
        handler(newVal,oldVal){
          this.getDetailInfo()
        }
      }
    },
    created(){
      this.getDetailInfo()
    },
    methods:{
      getDetailInfo(){ // 获取项目详情
        getEasyMainInfo({id:this.id}).then(res=>{
          console.log(res,'=====')
          let {success,data} = res.data
          if(success){
            this.info = data
            this.flag = true
            console.log(this.info ,'66666')
          }
        })
      }
    }

};
</script>
<style lang="less">
@import '~@/assets/style/maintain/maintaindetail.less';
</style>